/* eslint-disable */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { CasinoState } from './aggregators.types';
import {
  fetchAggregatorsThunks,
  fetchCasinoAggregatorsThunks,
  synchProvidersGamesThunks,
} from './aggregators.thunks';

const initialState: CasinoState = {
  aggregators: [],
  selectedAggregators: [],
  loading: false,
  error: null,
};

const aggregatorsSlice = createSlice({
  name: 'aggregators',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(fetchCasinoAggregatorsThunks.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        fetchCasinoAggregatorsThunks.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.aggregators = action.payload;
        },
      )
      .addCase(fetchCasinoAggregatorsThunks.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'Failed to fetch aggregators';
      })
      .addCase(fetchAggregatorsThunks.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        fetchAggregatorsThunks.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.selectedAggregators = action.payload;
        },
      )
      .addCase(fetchAggregatorsThunks.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'Failed to fetch aggregators';
      })
      .addCase(synchProvidersGamesThunks.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        synchProvidersGamesThunks.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.selectedAggregators = [];
        },
      )
      .addCase(synchProvidersGamesThunks.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'Failed to fetch aggregators';
      });
  },
});

export default aggregatorsSlice.reducer;

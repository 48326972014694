/* eslint-disable */
import React, { useState, useEffect } from 'react';
import {
  Modal,
  Form,
  Input,
  Upload,
  Image,
  Select,
  message,
  ColorPicker,
  Button,
} from 'antd';
import { PlusOutlined } from '@ant-design/icons';

import {
  readOnlymodalConfigForChange,
  selectConfigs,
  singleChoicemodalConfig,
} from '../gamesConfig/generalGamesTable.config';
import {
  modalConfig,
  readOnlymodalConfig,
} from '../gamesConfig/generalGamesTable.config';

import styles from './Games.module.scss';

import type { ColorPickerProps, UploadFile, UploadProps } from 'antd';
import { BASE_URL } from 'api/axios';
import { useSelector } from 'react-redux';
import { selectAuthUserInfo } from 'redux/reducers/serverConfigs/serverConfigs.slice';

interface EditGameModalProps {
  visible: boolean;
  editingGame: any;
  setEditingGame: (game: any) => void;
  handleOk: () => void;
  handleCancel: () => void;
}

const getBase64 = (file: File): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = error => reject(error);
  });

const EditGameModal: React.FC<EditGameModalProps> = ({
  visible,
  editingGame,
  setEditingGame,
  handleOk,
  handleCancel,
}) => {
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState(editingGame?.image || '');
  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [canUpdateImage, setCanUpdateImage] = useState(false);
  const authInfo = useSelector(selectAuthUserInfo);

  useEffect(() => {
    if (editingGame?.image) {
      const imageUrl = editingGame.image;
      setFileList([
        {
          uid: '-1',
          name: imageUrl,
          status: 'done',
          url: imageUrl,
        },
      ]);
      setPreviewImage(imageUrl);
    }
  }, [editingGame, visible]);

  const handlePreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as File);
    }
    setPreviewImage(file.url || (file.preview as string));
    setPreviewOpen(true);
  };

  const handleChange: UploadProps['onChange'] = ({ fileList: newFileList }) => {
    setFileList(newFileList);
    if (newFileList[0]?.originFileObj) {
      const newUpladed = URL.createObjectURL(newFileList[0].originFileObj);
      setEditingGame((prev: any) => ({
        ...prev,
        image: newUpladed,
      }));
    } else {
      setEditingGame((prev: any) => ({
        ...prev,
        image: '',
      }));
      console.error('No file object found.');
    }
  };

  const fields = editingGame ? Object.keys(editingGame) : [];
  const filteredFields = fields.filter(
    item =>
      !['id', 'uuid', 'image', 'status', 'createdAt', 'updatedAt'].includes(
        item,
      ),
  );

  const handleColorChange = (color: string) => {
    setEditingGame((prev: any) => ({
      ...prev,
      markerType: color,
    }));
  };

  return (
    <Modal
      title={<span className={styles.modalTitle}>Edit Game</span>}
      visible={visible}
      onOk={handleOk}
      onCancel={handleCancel}>
      <div className={styles.modalContent}>
        {editingGame && (
          <div className={styles.formContainer}>
            <Form>
              {filteredFields.map((item: string) => {
                const value = editingGame[item];

                return (
                  <div className={styles.fieldGroup} key={item}>
                    <span className={styles.label}>{modalConfig[item]}</span>
                    <Form.Item
                      className={styles.inputField}
                      validateStatus={
                        item === 'priority' &&
                        (Number(value) < 1 ||
                          Number(value) > 10000 ||
                          !Number.isInteger(Number(value)))
                          ? 'error'
                          : item === 'markerText' &&
                            typeof value === 'string' &&
                            value.length > 15
                          ? 'error'
                          : ''
                      }
                      help={
                        (item === 'priority' &&
                          (Number(value) < 1 ||
                            Number(value) > 10000 ||
                            !Number.isInteger(Number(value))) && (
                            <div
                              style={{
                                margin: 3,
                                fontSize: '11.3px',
                                lineHeight: '1.5',
                              }}>
                              Please, enter numbers from 1 to 10.000 for
                              Priority
                            </div>
                          )) ||
                        (item === 'markerText' &&
                          typeof value === 'string' &&
                          value.length > 15 && (
                            <div
                              style={{
                                margin: 3,
                                fontSize: '11.3px',
                                lineHeight: '1.5',
                              }}>
                              The maximum allowed number of characters is 15
                            </div>
                          ))
                      }>
                      {singleChoicemodalConfig.includes(item) ? (
                        <Select
                          value={
                            singleChoicemodalConfig.includes(item) &&
                            selectConfigs[item]
                              ? selectConfigs[item][value] || ''
                              : ''
                          }
                          onChange={newValue => {
                            setEditingGame({
                              ...editingGame,
                              [item]: newValue,
                            });
                          }}
                          disabled={readOnlymodalConfig.includes(item)}>
                          {selectConfigs[item] &&
                            Object.entries(
                              selectConfigs[item] as Record<string, string>,
                            ).map(([key, label]) => (
                              <Select.Option key={key} value={key}>
                                {label}
                              </Select.Option>
                            ))}
                        </Select>
                      ) : item === 'markerType' ? (
                        <ColorPicker
                          value={editingGame.markerType || '#1677ff'}
                          onChange={color => {
                            const hexColor = color.toHexString();
                            handleColorChange(hexColor);
                          }}
                          showText
                          format='hex'
                        />
                      ) : readOnlymodalConfigForChange[item] ? (
                        <Input
                          value={
                            readOnlymodalConfigForChange[
                              item as keyof typeof readOnlymodalConfigForChange
                            ]?.[value] || ''
                          }
                          disabled
                          style={{ backgroundColor: '#f5f5f5' }}
                        />
                      ) : (
                        <Input
                          value={value}
                          onChange={e => {
                            const newValue = e.target.value;

                            setEditingGame({
                              ...editingGame,
                              [item]: newValue,
                            });
                          }}
                          onBlur={() => {}}
                          disabled={readOnlymodalConfig.includes(item)}
                          style={
                            item === 'priority' && value !== undefined
                              ? {
                                  borderColor:
                                    Number(value) >= 1 && Number(value) <= 10000
                                      ? ''
                                      : 'red',
                                }
                              : {}
                          }
                        />
                      )}
                    </Form.Item>
                  </div>
                );
              })}

              <div className={styles.fieldGroup}>
                <span className={styles.label}>Image</span>
                <Form.Item className={styles.inputField}>
                  <Upload
                    name='image'
                    action={`${BASE_URL}/casino/games/${editingGame.provider}/${editingGame.uuid}/${editingGame.id}`}
                    listType='picture-card'
                    fileList={fileList}
                    onPreview={handlePreview}
                    onChange={handleChange}
                    method='PUT'
                    headers={{
                      'X-Auth-Token': authInfo.token,
                    }}
                    beforeUpload={file => {
                      const isImage = file.type.startsWith('image/');
                      if (!isImage) {
                        message.error('You can only upload image format!');
                      }
                      return isImage || Upload.LIST_IGNORE;
                    }}>
                    {fileList.length >= 1 ? null : (
                      <div>
                        <PlusOutlined />

                        <div
                          style={{
                            margin: 3,
                            fontSize: '11.5px',
                            lineHeight: '1.5',
                          }}>
                          Upload Picture
                        </div>
                      </div>
                    )}
                  </Upload>
                  {editingGame.image && (
                    <>
                      <Image
                        wrapperStyle={{ display: 'none' }}
                        preview={{
                          visible: previewOpen,
                          onVisibleChange: item => setPreviewOpen(item),
                        }}
                        src={editingGame.image}
                      />
                      {canUpdateImage && (
                        <Button
                          type='primary'
                          onClick={() => {
                            setFileList([]);
                            setEditingGame({
                              ...editingGame,
                              image: '',
                            });
                            setCanUpdateImage(false);
                          }}>
                          Update Image
                        </Button>
                      )}
                    </>
                  )}
                  <div className={styles.recommendedSize}>
                    Recommended size - 366 x 213 px
                  </div>
                </Form.Item>
              </div>
            </Form>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default EditGameModal;

import { combineReducers } from '@reduxjs/toolkit';

import serverConfigs from './serverConfigs/serverConfigs.slice';
import appConfigs from './appConfigs/appConfigs.slice';
import notifications from './notifications/notifications.slice';
import dashboardSlice from './dashboard/dashboard.slice';
import projectSlice from './projects/projectList.slice';
import pageSeoDataSlice from './seo/seo.slice';
import paymentConfigsSlice from './paymentConfigs/paymentConfigs.slice';
import aggregators from './casino/aggregators/aggregators.slice';
import games from './casino/games/games.slice';

export const reducers = combineReducers({
  serverConfigs,
  appConfigs,
  notifications,
  dashboard: dashboardSlice,
  project: projectSlice,
  seoPage: pageSeoDataSlice,
  paymentConfigs: paymentConfigsSlice,
  aggregators,
  games,
});

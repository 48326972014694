import React from 'react';
import { Checkbox } from 'antd';
import { CheckboxOptionType } from 'antd/es/checkbox/Group';

import { checkboxGroups } from 'components/Routes/Casino/Games/gamesConfig/generalGamesTable.config';

import style from './Checkbox.module.scss';

type CheckboxComponentProps = {
  groups: typeof checkboxGroups;
  onChange: (updatedCheckedList: any) => void;
  checkedList: { [key: string]: string[] };
};

const CheckboxList: React.FC<CheckboxComponentProps> = ({
  groups,
  onChange,
  checkedList,
}) => {
  const handleGroupChange = (name: string, list: CheckboxOptionType[]) => {
    onChange({ ...checkedList, [name]: list });
  };

  const handleCheckAllChange = (name: string) => {
    const allOptions = groups.find(group => group.name === name)?.options || [];
    const isAllChecked = checkedList[name]?.length === allOptions.length;

    if (isAllChecked) {
      onChange({ ...checkedList, [name]: [] });
    } else {
      onChange({ ...checkedList, [name]: allOptions });
    }
  };

  return (
    <div className={style.checkbox}>
      {groups.map(group => {
        const checkAll =
          group.options.length === checkedList[group.name]?.length;
        const indeterminate =
          checkedList[group.name]?.length > 0 &&
          checkedList[group.name]?.length < group.options.length;

        return (
          <div key={group.name}>
            <h4 className={style.checkbox_name}> {group.label}:</h4>

            <Checkbox
              indeterminate={indeterminate}
              onChange={() => handleCheckAllChange(group.name)}
              checked={checkAll}>
              All
            </Checkbox>
            <Checkbox.Group
              className={style.che}
              options={group.options}
              value={checkedList[group.name]}
              onChange={(list: any) => handleGroupChange(group.name, list)}
            />
          </div>
        );
      })}
    </div>
  );
};

export default CheckboxList;

/* eslint-disable */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {
  fetchGeneralGamesThunks,
  filterGamesThunk,
  searchGamesThunk,
} from './games.thunks';

const initialState: any = {
  allGames: [],
  gameCount: 0,
  loading: false,
  error: null,
};

const gamesSlice = createSlice({
  name: 'games',
  initialState,
  reducers: {
    updateGame: (state, action: PayloadAction<any>) => {
      const updatedGame = action.payload;
      const index = state.allGames.findIndex(
        (game: any) => game.id === updatedGame.id,
      );
      if (index !== -1) {
        state.allGames[index] = updatedGame;
      }
    },
  },
  extraReducers: builder => {
    builder

      .addCase(fetchGeneralGamesThunks.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        fetchGeneralGamesThunks.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.allGames = action.payload;
          state.gameCount = action.payload.count;
        },
      )
      .addCase(fetchGeneralGamesThunks.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'Failed to fetch general games';
      })
      .addCase(searchGamesThunk.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        searchGamesThunk.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.allGames = action.payload;
        },
      )
      .addCase(searchGamesThunk.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message || 'Failed to fetch general games';
      })
      .addCase(filterGamesThunk.pending, state => {
        state.loading = true;
        state.error = null;
      })
      .addCase(
        filterGamesThunk.fulfilled,
        (state, action: PayloadAction<any>) => {
          state.loading = false;
          state.allGames = action.payload.dataList;
          state.gameCount = action.payload.count;
        },
      )
      .addCase(filterGamesThunk.rejected, (state, action) => {
        state.loading = false;
        state.error =
          action.payload || action.error.message || 'Failed to filter games';
      });
  },
});

export default gamesSlice.reducer;
export const { updateGame } = gamesSlice.actions;

/* eslint-disable */
import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';

export const fetchCasinoAggregatorsThunks = createAsyncThunk<any[]>(
  'aggregators/fetchCasinoAggregatorsThunks',
  async (_, thunkAPI) => {
    try {
      const response = await axios.post('/casino/aggregators');
      return response.data.itemsList;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  },
);

export const fetchAggregatorsThunks = createAsyncThunk(
  'aggregators/fetchAggregatorsThunks',
  async (selectedAggregator: any, thunkAPI) => {
    try {
      const requestData = selectedAggregator.map((item: any) => item.name);
      const response = await axios.post('/casino/aggregators/games', {
        aggregators: requestData,
      });
      return [response.data];
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  },
);



export const synchProvidersGamesThunks = createAsyncThunk(
  'casino/synchProvidersGamesThunks',
  async (
    {
      synchGames,
      choosenAggregators,
    }: { synchGames: any; choosenAggregators: string[] },
    thunkAPI,
  ) => {
    const maxRetries = 5;
    let attempts = 0;

    const request = async (): Promise<any> => {
      try {
        const syncData = {
          aggregators: choosenAggregators,
          deleted: synchGames,
        };

        const response = await axios.put('casino/games-sync', syncData);

        return response.data;
      } catch (error: any) {
        if (error.code === 'ECONNABORTED' && attempts < maxRetries) {
          attempts += 1;
          await new Promise(resolve => setTimeout(resolve, 1000));
          return request();
        }

        return Promise.reject(error.message);
      }
    };

    return request();
  },
);

/* eslint-disable */
import { createAsyncThunk } from '@reduxjs/toolkit';
import axios from 'axios';
import { updateGame } from './games.slice';

function transformKeysToBoolean(obj: any) {
  for (const key in obj) {
    if (key === 'hasDemo') {
      obj[key] = obj[key] === 'Yes';
    } else if (key === 'hasFreespins') {
      obj[key] = obj[key] === 'Yes';
    } else if (key === 'visibility') {
      obj[key] = obj[key] === 'Visible';
    }
  }

  return obj;
}

function processKeys(obj: any) {
  for (const key in obj) {
    if (Array.isArray(obj[key])) {
      if (
        obj[key].length === 2 &&
        key != 'siteSection' &&
        key != 'deviceSupport' &&
        key != 'type'
      ) {
        console.log(obj[key]);

        delete obj[key];
      } else if (
        obj[key].length === 1 &&
        key != 'siteSection' &&
        key != 'deviceSupport' &&
        key != 'type'
      ) {
        obj[key] = obj[key][0];
      } else if (obj[key].length === 0) {
        delete obj[key];
      }
    }
  }

  return transformKeysToBoolean(obj);
}

export const fetchGeneralGamesThunks = createAsyncThunk(
  'casino/fetchGeneralGamesThunks',

  async (
    { page = 1, limit = 20 }: { page?: number; limit?: number },
    thunkAPI,
  ) => {
    try {
      const response = await axios.post('/casino/games/filtered', {
        filters: {},
        pagination: { page, limit, sort: 'id', order: 'desc' },
      });
      return response.data.dataList;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  },
);

export const updateCurrentGameThunks = createAsyncThunk(
  'games/fetchGeneralGamesThunks',
  async (editingGame: any, thunkAPI) => {
    try {
      const { provider, ...gameWithoutProvider } = editingGame;
      const response = await axios.put(
        `casino/games/${editingGame.provider}/${editingGame.uuid}/${editingGame.id}`,
        gameWithoutProvider,
      );
      thunkAPI.dispatch(updateGame(editingGame));

      return response.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  },
);

export const searchGamesThunk = createAsyncThunk(
  'games/searchGamesThunk',
  async (
    {
      keyword,
      siteSection,
      mobile,
    }: { keyword: string; siteSection: string; mobile: boolean },
    thunkAPI,
  ) => {
    try {
      const response = await axios.post('/casino/games/search', {
        keyword,
        siteSection,
        mobile,
      });
      return response.data.data;
    } catch (error: any) {
      return thunkAPI.rejectWithValue(error.message);
    }
  },
);

export const filterGamesThunk = createAsyncThunk(
  'games/filterGames',
  async (
    { body, additionalProps }: { body: any; additionalProps: any },
    { rejectWithValue },
  ) => {
    let x = processKeys(body);
    try {
      const response = await axios.post(
        `/casino/games/filtered?limit=${additionalProps.pageSize}&page=${additionalProps.current}`,
        {
          filters: x,
        },
      );
      return response.data;
    } catch (error: any) {
      return rejectWithValue(
        error.response?.data?.message || 'Failed to filter games',
      );
    }
  },
);

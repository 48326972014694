const statusConfig: any = {
  NEW: '#faad14',
  UPDATE: '#73d13d',
  DELETE: '#ff4d4f',
};

export const STATUSCONFIG = ['NEW', 'UPDATE', 'DELETE'];

export const selecetdDataConfig = [
  { title: 'ID', dataIndex: 'id', key: 'id' },
  { title: 'Name', dataIndex: 'name', key: 'name' },
  {
    title: 'UUID',
    dataIndex: 'uuid',
    key: 'uuid',
  },
  { title: 'URL', dataIndex: 'url', key: 'url' },
  {
    title: 'Free Spins',
    dataIndex: 'hasFreespins',
    key: 'hasFreespins',
    render: (text: boolean) => (text ? 'Yes' : 'No'),
  },
  {
    title: 'Demo Available',
    dataIndex: 'hasDemo',
    key: 'hasDemo',
    render: (text: boolean) => (text ? 'Yes' : 'No'),
  },
  {
    title: 'Device Support',
    dataIndex: 'deviceSupport',
    key: 'device_support',
  },
  {
    title: 'Image',
    dataIndex: 'image',
    key: 'image',
    render: (image: string, item: any) => {
      return <img src={image} alt={item.name} style={{ width: 50 }} />;
    },
  },
  { title: 'Priority', dataIndex: 'priority', key: 'priority' },
  { title: 'Marker Type', dataIndex: 'markerType', key: 'markerType' },
  { title: 'Marker Text', dataIndex: 'markerText', key: 'markerText' },
  {
    title: 'Deleted',
    dataIndex: 'deleted',
    key: 'deleted',
    render: (text: number) => (text ? 'Yes' : 'No'),
  },
  { title: 'Frame Size', dataIndex: 'frameSize', key: 'frameSize' },
  { title: 'Created At', dataIndex: 'createdAt', key: 'createdAt' },
  { title: 'Updated At', dataIndex: 'updatedAt', key: 'updatedAt' },
  { title: 'Type', dataIndex: 'type', key: 'type' },
  { title: 'Percent 1', dataIndex: 'percent1', key: 'percent1' },
  { title: 'Percent 2', dataIndex: 'percent2', key: 'percent2' },
  {
    title: 'Visibility',
    dataIndex: 'visibility',
    key: 'visibility',
  },
  { title: 'Aggregator', dataIndex: 'aggregator', key: 'aggregator' },
  { title: 'Provider', dataIndex: 'provider', key: 'provider' },
  { title: 'Site Section', dataIndex: 'siteSection', key: 'site_section' },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status',
    render: (status: number) => {
      return (
        <div
          style={{
            backgroundColor: statusConfig[STATUSCONFIG[status]],
            padding: '6px 10px',
            borderRadius: '5px',
            marginRight: '8px',
            textAlign: 'center',
          }}>
          {STATUSCONFIG[status]}
        </div>
      );
    },
  },
];

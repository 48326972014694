/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { Button, Input, Space, Table, Empty } from 'antd';
import { EditTwoTone, SearchOutlined } from '@ant-design/icons';

import { useAppSelector, useAppDispatch } from 'redux/hooks/redux.hooks';
import {
  filterGamesThunk,
  searchGamesThunk,
  updateCurrentGameThunks,
} from 'redux/reducers/casino/games/games.thunks';
import { fetchGeneralGamesThunks } from 'redux/reducers/casino/games/games.thunks';
import { GeneralGamesTableConfig } from 'redux/reducers/casino/games/games.types';

import EditGameModal from './EditGameModal';
import { RootState } from 'redux/store.types';
import { useSelector } from 'react-redux';
import img from '../../../../../assets/images/icon.jpg';
import { updateGame } from 'redux/reducers/casino/games/games.slice';

const GeneralGamesTable: React.FC<GeneralGamesTableConfig> = ({
  generalGamesTableConfig,
  setCurrentPage,
  currentPage,
}) => {
  const dispatch = useAppDispatch();
  const { allGames, gameCount } = useAppSelector((state: any) => state.games);

  const [isModalVisible, setIsModalVisible] = useState(false);
  const [editingGame, setEditingGame] = useState<any>(null);
  const [pagination, setPagination] = useState({
    current: currentPage.current,
    pageSize: currentPage.pageSize,
  });
  const [searchKeyword, setSearchKeyword] = useState('');
  const [searchResults, setSearchResults] = useState<any[]>([]);
  const { loading } = useSelector((state: RootState) => state.games);

  const [columnFilters, setColumnFilters] = useState<{
    [key: string]: string;
  }>();

  const handleEdit = (record: any) => {
    setEditingGame(record);
    setIsModalVisible(true);
  };

  const handleOk = () => {
    console.log(editingGame, 'mayis');

    if (editingGame) {
      dispatch(updateCurrentGameThunks(editingGame));
    }
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setEditingGame(null);
  };

  useEffect(() => {
    setCurrentPage(pagination);
  }, [pagination]);

  const handleSearch = () => {
    if (searchKeyword) {
      dispatch(
        searchGamesThunk({
          keyword: searchKeyword,
          siteSection: 'casino',
          mobile: false,
        }),
      );
    } else {
      setSearchResults([]);
    }
  };

  const handleSearchh = (selectedKeys: string[], dataIndex: any) => {
    setColumnFilters(prev => ({
      ...prev,
      [dataIndex]: selectedKeys[0],
    }));
  };

  useEffect(() => {
    if (columnFilters && Object.keys(columnFilters).length > 0) {
      dispatch(
        filterGamesThunk({
          body: { ...columnFilters },
          additionalProps: pagination,
        }),
      ).then((action: any) => {
        if (filterGamesThunk.fulfilled.match(action)) {
          setSearchResults(action.payload);
        }
      });
    }
  }, [columnFilters]);

  const handleTableChange = (page: any) => {
    setPagination(page);
  };

  const handleReset = (clearFilters: () => void) => {
    clearFilters();
    setColumnFilters({});
  };

  const getColumnSearchProps = (dataIndex: string) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, clearFilters }: any) => (
      <div style={{ padding: 8 }} onKeyDown={e => e.stopPropagation()}>
        <Input
          placeholder={`Search ${dataIndex}`}
          value={selectedKeys[0]}
          onChange={e =>
            setSelectedKeys(e.target.value ? [e.target.value] : [])
          }
          style={{ marginBottom: 8, display: 'block' }}
        />
        <Space>
          <Button
            type='primary'
            onClick={() => handleSearchh(selectedKeys as string[], dataIndex)}
            icon={<SearchOutlined />}
            size='small'
            style={{ width: 90 }}>
            Search
          </Button>
          <Button
            onClick={() => clearFilters && handleReset(clearFilters)}
            size='small'
            style={{ width: 90 }}>
            Reset
          </Button>
        </Space>
      </div>
    ),
    filterIcon: (filtered: boolean) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
  });

  const updatedColumns = generalGamesTableConfig.map((col: any) => ({
    ...col,
    ...(['aggregator', 'provider', 'url'].includes(col.dataIndex)
      ? getColumnSearchProps(col.dataIndex)
      : {}),
    title: col.title,
  }));

  return (
    <>
      <Space style={{ marginBottom: 16 }}>
        <Input
          value={searchKeyword}
          onChange={e => setSearchKeyword(e.target.value)}
          placeholder='Search for a game'
        />
        <Button onClick={handleSearch} type='primary'>
          Search
        </Button>
      </Space>

      <Table
        loading={loading}
        columns={[
          updatedColumns[0],
          {
            title: 'Edit',
            key: 'actions',
            render: (record: any) => (
              <EditTwoTone onClick={() => handleEdit(record)} />
            ),
          },
          ...updatedColumns.slice(1),
        ]}
        dataSource={allGames}
        rowKey='id'
        pagination={{
          current: currentPage.current,
          pageSize: pagination.pageSize,
          total: gameCount,
          showSizeChanger: false,
          onChange: (page, pageSize) =>
            setPagination({ current: page, pageSize }),
        }}
        onChange={handleTableChange}
        scroll={{
          x: 'max-content',
        }}
        locale={{
          emptyText: (
            <div style={{ textAlign: 'center' }}>
              <Empty image={<img src={img} />} description='No Game Found' />
            </div>
          ),
        }}
      />

      <EditGameModal
        visible={isModalVisible}
        editingGame={editingGame}
        setEditingGame={setEditingGame}
        handleOk={handleOk}
        handleCancel={handleCancel}
      />
    </>
  );
};

export default GeneralGamesTable;

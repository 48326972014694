import React from 'react';
import { Table } from 'antd';

interface GamesTableProps {
  data: any;
  selecetdDataConfig: any;
}

const GamesTable: React.FC<GamesTableProps> = ({
  data,
  selecetdDataConfig,
}) => {
  return (
    <>
      <Table
        columns={selecetdDataConfig}
        dataSource={data}
        rowKey='id'
        pagination={false}
        scroll={{
          x: 'max-content',
        }}
      />
    </>
  );
};

export default GamesTable;
